<template>
  <div>
    <section class="hero reviews grid-bg">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-centered">Customer Reviews</h2>
          <div class="columns">
            <div class="column">
              <div class="card r1">
                <div class="card-content">
                  <p class="title">
                    “I am an engineer myself but never have i imagined the
                    subject to be such fun.. My kids loved it.”
                  </p>
                  <img src="../assets/reviews/rosh.png" class="review" />
                  <p style="display: inline; padding-bottom: 15px">by Rosh</p>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="card r2">
                <div class="card-content">
                  <p class="title">
                    “I bought this kit to teach robotics to a rural kids camp,
                    had great fun, it was much easier to engage & educate
                    robotics than earlier.”
                  </p>
                  <img src="../assets/reviews/vidhi.png" class="review" />
                  <p style="display: inline; padding-bottom: 15px">by Vidhi</p>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="card r3">
                <div class="card-content">
                  <p class="title">
                    “This kit is an excellent example of teaching kids
                    innovative solutions through robotics.”
                  </p>
                  <img src="../assets/reviews/shuvro.png" class="review" />
                  <p style="display: inline; padding-bottom: 15px">by Shuvro</p>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <a
              href="https://amazon.in/product-reviews/B075XP5SRR/"
              target="blank"
              class="button"
              >Amazon Reviews</a
            >
          </div>
        </div>
      </div>
    </section>
    <section class="hero" style="margin-bottom: 2rem">
      <div class="hero-body">
        <div class="container">
          <h2 class="subtitle has-text-centered">Featured on</h2>
          <div class="featuredOn">
            <a
              target="blank"
              href="https://cio.eletsonline.com/news/witblox-raises-rs-1-3-crore-mumbai-angels-network/64241/"
            >
              <img src="../assets/featured/cio.png" alt="..." />
            </a>
            <a
              target="blank"
              href="https://www.theindianwire.com/startups/witblox-secures-₹1-3-crore-mumbai-angels-network-241909/"
            >
              <img src="../assets/featured/indian-wire.png" alt="..." />
            </a>
            <a
              target="blank"
              href="https://yourstory.com/2019/12/funding-alert-edtech-startup-witblox-mumbai-angels"
            >
              <img src="../assets/featured/yourstory.svg" alt="..." />
            </a>
            <a
              target="blank"
              href="https://techstory.in/witblox-secured-funding-of-rs-1-3-cr-from-mumbai-angels-network/"
            >
              <img src="../assets/featured/techstory.jpg" alt="..." />
            </a>
            <a
              target="blank"
              href="https://www.newindianexpress.com/cities/kochi/2019/dec/14/sustainable-playgrounds-drone-races-to-awe-visitors-2075967.html"
            >
              <img src="../assets/featured/indianexpress.jpg" alt="..." />
            </a>
            <a
              target="blank"
              href="https://www.techcircle.in/2019/12/18/mumbai-angels-backs-robotics-kit-maker-for-kids-witblox"
            >
              <img src="../assets/featured/techcircle.png" alt="..." />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="hero kid">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6">
              <h2 class="title">Get your witblox today!</h2>
              <p class="subtitle">
                It's a perfect Robotics Learning Tool for children. It helps
                children learn Design, Hardware, Electronics and Programming.
              </p>
              <a
                class=""
                href="https://www.amazon.in/WitBlox-Mega-DIY-Robotics-Projects/dp/B075XP5SRR"
              >
                <img
                  src="../assets/compressed/amazon.png"
                  data-src="../assets/img/amazon.png"
                  alt=""
                  class="amazon"
                />
              </a>
              <a
                class="button is-large is-light"
                href="https://shop.witblox.com/FUPqjSYHqVhWcwvapbzN"
              >
                Store
              </a>
            </div>
            <div class="column is-4">
              <img
                src="../assets/compressed/kid.png"
                data-src="../assets/img/kid.png"
                class="kidImg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footer">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered is-one-quarter">
            <div class="columns">
              <div class="column">
                <img
                  src="../assets/compressed/witblox.png"
                  data-src="../assets/img/witblox.png"
                  alt=""
                  class=""
                />
              </div>
            </div>
            <div class="columns">
              <div class="column social">
                <a href="https://twitter.com/WitbloxIndia" target="blank">
                  <i class="fab fa-2x fa-twitter"></i>
                </a>
                <a href="https://facebook.com/witblox" target="blank">
                  <i class="fab fa-2x fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/witblox_" target="blank">
                  <i class="fab fa-2x fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCLdCQ27ir86tfVrNyyrbJfA"
                  target="blank"
                >
                  <i class="fab fa-2x fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="column">
            <p class="subtitle">More</p>
            <div class="columns">
              <div class="column">
                <ul>
                  <li>
                    <a class="link" href="/" data-navigo> Homepage </a>
                  </li>
                  <li>
                    <a class="link" href="makers" data-navigo> Makers </a>
                  </li>
                  <li>
                    <a class="link" href="challenges" data-navigo>
                      Challenges
                    </a>
                  </li>
                </ul>
              </div>
              <div class="column">
                <ul>
                  <li>
                    <a class="link" href="https://shop.witblox.com//"> Shop </a>
                  </li>
                  <li>
                    <a class="link" href="terms" data-navigo> Terms </a>
                  </li>
                  <li>
                    <a class="link" href="privacy" data-navigo>
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div class="column">
                <ul>
                  <li>
                    <a class="link" href="/partner-with-us/">
                      Partner with us
                    </a>
                  </li>
                  <li>
                    <a class="link" href="about" data-navigo> About Us </a>
                  </li>
                  <li>
                    <a class="link" href="warranty" data-navigo>
                      Register for Warranty
                    </a>
                  </li>
                  <li>
                    <a
                      class="link"
                      href="https://wa.me/919987213420"
                      data-navigo
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column">
            <div class="content has-text-centered">
              <p>
                <strong>Witblox</strong> &bull; &copy; We Techshiksha P Ltd
                2012-2019. Designed with <i class="fas fa-heart"></i> in India.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style scoped>
.column {
  margin: auto;
}

.featuredOn {
  text-align: center;
}

.featuredOn img {
  padding: 20px;
  height: 70px;
}

.grid-bg {
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4"%3E%3Cpath fill="%239C92AC" fill-opacity="0.4" d="M1 3h1v1H1V3zm2-2h1v1H3V1z"%3E%3C/path%3E%3C/svg%3E');
}

.grid-bg .title {
  padding-bottom: 25px;
}

.reviews .card {
  border-radius: 10px;
}

.reviews .card .title {
  font-size: 1.5rem;
  color: #fff;
}

.reviews .card p {
  color: #fff;
}

.reviews .card .title {
  font-size: 1.5rem;
  color: #fff;
}

.reviews .card img {
  width: 45px;
  margin-right: 5px;
  border-radius: 50%;
}

.r1 {
  background: #bd114e;
  transform: rotate(3deg);
}

.r2 {
  background: #ed7f1e;
  transform: rotate(-2deg);
}

.r3 {
  background: #723389;
  transform: rotate(2deg);
}

.button {
  color: #6b48ff;
  border: 2.5px #6b48ff solid;
}

.r1:hover,
.r2:hover,
.r3:hover {
  transform: rotate(0deg);
  box-shadow: 10px 10px 5px #7a7a7a;
}

.social a {
  padding: 3px;
}

.amazon {
  width: 115px;
}

.kid .hero-body {
  background: #6b48ff;
  padding: 0rem 1.5rem 0.75rem 1.5rem !important;
}

.kid .title,
.kid .subtitle {
  color: #fff;
}

.kid .title {
  font-size: 1.8rem;
}

.kid .hero-body .column.is-4 {
  padding: 0px !important;
  height: 290px;
  position: relative;
}

.kidImg {
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 992px) {
  .kid .hero-body {
    padding: 4rem 1.5rem 0rem 1.5rem !important;
  }

  .kid .hero-body .column.is-4 {
    padding: 0px !important;
    height: auto;
    position: inherit;
  }

  .kidImg {
    position: inherit;
    padding-bottom: 5px;
  }
}
</style>
<template>
  <div>
    <!-- mobile-nav -->
    <nav class="navigationbar is-fixed-top">
      <div
        class="is-flex"
        style="
          justify-content: space-between;
          padding: 0 1rem;
          align-items: center;
        "
      >
        <a href="/">
          <img src="https://witblox.com/assets/img/witblox.png" />
        </a>
        <a
          class="is-flex"
          href="https://wa.me/919987213420?text=Hi, I am interested in enrolling in a WitBlox class."
          style="color: #00a859; align-items: center"
        >
          <div style="line-height: 0.9rem">
            <p>Contact</p>
            <p>Teacher</p>
          </div>
          <img src="/whatsapp.png" />
        </a>
      </div>
    </nav>
    <!-- /mobile-nav -->
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.navigationbar {
  padding: 15px 0;
}
.navigationbar img {
  width: 50px;
}
</style>

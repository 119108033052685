<template>
  <main>
    <!-- /desktop-nav -->
    <!-- heading -->
    <section id="heading" class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column">
              <h1 class="title has-text-centered">
                {{ teacher.txt }}
              </h1>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4" v-for="cls in classes" :key="cls.id">
              <img
                @click="
                  $router.push(
                    '/class/' + cls.id + '/' + $router.currentRoute.params.id
                  )
                "
                :src="cls.image"
                style="width: 100%"
                alt=""
              />
              <a
                @click="
                  $router.push(
                    '/class/' + cls.id + '/' + $router.currentRoute.params.id
                  )
                "
                class="button is-fullwidth"
                >Know More</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /heading -->
  </main>
</template>

<script>
import { db } from "@/main.js";
export default {
  name: "teacher",
  data() {
    return {
      classes: [],
      teacher: {},
    };
  },
  beforeMount() {
    // console.log(this.$router.currentRoute.params.id);
    db.collection("teachers")
      .where("slug", "==", this.$router.currentRoute.params.id)
      .get()
      .then((teacher) => {
        this.teacher = teacher.docs[0].data();
        this.teacher.id = teacher.docs[0].id;
      });
    db.collection("classes")
      .get()
      .then((snap) => {
        snap.forEach((cls) => {
          this.classes.push({ id: cls.id, image: cls.data().featured });
        });
      });
  },
};
</script>
<style scoped>
.button.is-fullwidth {
  border: 4px solid #000;
  border-radius: 10px;
  font-weight: 800;
  padding: 20px 0;
}
</style>
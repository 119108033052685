<template>
  <main>
    <!-- /desktop-nav -->
    <!-- heading -->
    <section id="heading" class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column">
              <h1 class="title has-text-centered">Join a WitBlox Class</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="classcard" v-for="cls in classes" :key="cls.id">
                <img
                  @click="$router.push('/class/' + cls.id)"
                  :src="cls.image"
                  style="width: 100%"
                  alt=""
                />
                <a
                  @click="$router.push('/class/' + cls.id)"
                  class="button is-fullwidth"
                  >Register</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /heading -->
  </main>
</template>

<script>
import { db } from "@/main.js";
export default {
  name: "home",
  data() {
    return {
      classes: [],
    };
  },
  beforeMount() {
    if (location.hostname == "demo.witblox.com") {
      db.collection("classes")
        .where("demo", "==", true)
        .orderBy("ordering")
        .get()
        .then((snap) => {
          snap.forEach((cls) => {
            var image = "";
            if (cls.data().webimg) image = cls.data().webimg;
            else image = cls.data().featured;
            this.classes.push({ id: cls.id, image: image });
          });
        });
    } else {
      db.collection("classes")
        .orderBy("ordering")
        .get()
        .then((snap) => {
          snap.forEach((cls) => {
            var image = "";
            if (cls.data().webimg) image = cls.data().webimg;
            else image = cls.data().featured;
            this.classes.push({ id: cls.id, image: image });
          });
        });
    }
  },
};
</script>
<style scoped>
.button.is-fullwidth {
  border: 4px solid #000;
  border-radius: 10px;
  font-weight: 800;
  padding: 20px 0;
}
.classcard {
  width: 50%;
  display: inline-block;
  padding: 12px;
}
@media only screen and (max-width: 600px) {
  .classcard {
    width: 100%;
    display: inline-block;
    padding: 12px;
  }
}
</style>
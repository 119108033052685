<template>
  <main>
    <div class="hero">
      <div class="hero-body details">
        <div class="container">
          <div class="columns">
            <div class="column">
              <p class="title has-text-centered">
                {{ cls.name }}
                <span v-if="teacher.id">by {{ teacher.name }}</span>
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-5">
              <video
                v-if="cls.webvideo"
                controls
                autoplay
                :src="cls.webvideo"
              ></video>
              <video v-else controls autoplay :src="cls.featuredVideo"></video>
              <!-- <div class="separator">ABOUT</div> -->
              <p style="padding: 0 20px" class="is-size-3">
                Fees - &#8377;{{ cls.webpricing }}/-
              </p>
              <p style="padding: 0 20px" class="is-size-6">
                (includes the kit & parts to build the project)
              </p>

              <img v-if="cls.webbanner" :src="cls.webbanner" alt="" />
              <img src="@/assets/img/class-certificate.png" alt="" />
            </div>
            <div class="column" style="padding: 0 30px">
              <div class="separator">DESCRIPTION</div>
              <div v-html="cls.desc"></div>
              <div class="separator">LESSONS</div>
              <div class="lessons">
                <div
                  v-for="lesson in cls.lessons"
                  :key="lesson.id"
                  class="columns is-mobile"
                >
                  <div class="column is-3">
                    <img :src="lesson.img" alt="" />
                  </div>
                  <div class="column">
                    <h6>{{ lesson.title }}</h6>
                    <p>{{ lesson.description }}</p>
                  </div>
                </div>
              </div>
              <div class="separator">FAQs</div>
              <div class="faq">
                <article v-for="faq in faqs" :key="faq.id" class="message">
                  <div class="message-header">
                    <p @click="faq.open = !faq.open">{{ faq.title }}</p>
                  </div>
                  <div class="message-body is-collapsible" v-if="faq.open">
                    <div class="message-body-content">
                      {{ faq.content }}
                    </div>
                  </div>
                </article>
              </div>
              <div v-if="teacher.id">
                <div class="separator" id="form">Register</div>
                <!-- <iframe :src="cls.form">Loading…</iframe> -->
                <div v-if="form.error" class="columns">
                  <div class="column">
                    <article class="message is-danger">
                      <div class="message-header">
                        <p>Error</p>
                        <button class="delete" aria-label="delete"></button>
                      </div>
                      <div class="message-body">
                        Please fill the entire form.
                      </div>
                    </article>
                  </div>
                </div>
                <div v-if="!form.completed" class="columns">
                  <div class="column">
                    <div class="field">
                      <p class="control">
                        <input
                          v-model="form.name"
                          class="input"
                          type="text"
                          placeholder="Name"
                        />
                      </p>
                    </div>
                    <div class="field">
                      <p class="control">
                        <input
                          v-model="form.email"
                          class="input"
                          type="email"
                          placeholder="Email"
                        />
                      </p>
                    </div>
                    <div class="field">
                      <p class="control">
                        <input
                          v-model.number="form.phone"
                          class="input"
                          type="number"
                          placeholder="Phone Number"
                        />
                      </p>
                    </div>
                    <div class="field">
                      <p class="control">
                        <input
                          v-model="form.city"
                          class="input"
                          type="text"
                          placeholder="City"
                        />
                      </p>
                    </div>
                    <div class="field">
                      <button
                        :class="{ 'is-loading': form.loading }"
                        @click="submitForm"
                        class="button is-medium is-fullwidth"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else class="columns">
                  <div class="column">
                    <article class="message is-primary">
                      <div class="message-header">
                        <p>Payment Received</p>
                      </div>
                      <div class="message-body">
                        Thankyou! Your class is now booked. We will send you an
                        email shortly with your class details.
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div v-if="teacher.id" class="trailerVidBtn">
                <div class="columns">
                  <div class="column">
                    <button
                      @click="fillForm"
                      class="button btn btn-theme is-fullwidth"
                    >
                      Interested?
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="!demolocation" class="trailerVidBtn">
          <div class="columns">
            <div class="column">
              <a @click="deeplink" class="button btn btn-theme is-fullwidth">
                Join
              </a>
            </div>
          </div>
        </div>
        <div v-else class="trailerVidBtn">
          <div class="columns">
            <div class="column">
              <a
                href="https://calendly.com/witblox2/witblox-free-workshop-for-you"
                target="blank"
                class="button btn btn-theme is-fullwidth"
              >
                Book a free demo
              </a>
            </div>
          </div>
        </div> -->
        <div class="trailerVidBtn">
          <div class="columns">
            <div class="column">
              <a
                target="blank"
                class="button btn btn-theme is-fullwidth"
                @click="paymentForm = true"
              >
                Book a class
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="paymentcollection" v-if="paymentForm">
      <div class="paymentForm">
        <div class="has-text-right">
          <a class="button" @click="paymentForm = false">Close</a>
        </div>
        <div class="separator" id="form">Register</div>
        <!-- <iframe :src="cls.form">Loading…</iframe> -->
        <div v-if="pform.error" class="columns">
          <div class="column">
            <article class="message is-danger">
              <div class="message-header">
                <p>Error</p>
                <button class="delete" aria-label="delete"></button>
              </div>
              <div class="message-body">{{ pform.error }}</div>
            </article>
          </div>
        </div>
        <div v-if="pform.stage == 0" class="columns">
          <div class="column">
            <div class="field">
              <p class="control">
                <input
                  v-model="pform.name"
                  class="input"
                  type="text"
                  placeholder="Name"
                />
              </p>
            </div>
            <div class="field">
              <p class="control">
                <input
                  v-model="pform.email"
                  class="input"
                  type="email"
                  placeholder="Email"
                />
              </p>
            </div>
            <div class="field">
              <p class="control">
                <input
                  v-model.number="pform.phone"
                  class="input"
                  type="number"
                  placeholder="Phone Number"
                />
              </p>
            </div>
            <div class="field">
              <p class="control">
                <input
                  v-model="pform.city"
                  class="input"
                  type="text"
                  placeholder="City"
                />
              </p>
            </div>
            <div class="field">
              <div class="select is-fullwidth">
                <select @change="pform.selectedSchedule = $event.target.value">
                  <option selected disabled>Select a schedule</option>
                  <option
                    v-for="schedule in pform.schedules"
                    :key="schedule.id"
                    @click="pform.selectedSchedule = schedule.id"
                  >
                    {{ schedule.title }} {{ schedule.subtitle }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <button
                :class="{
                  'is-loading': pform.loading,
                  disabled: pform.loading,
                }"
                @click="pForm(1)"
                class="button is-medium is-fullwidth"
                :disabled="pform.loading"
              >
                Pay & Book
              </button>
            </div>
          </div>
        </div>
        <div v-if="pform.stage == 1" class="columns">
          <div class="column">
            <div class="field">
              <div class="select is-fullwidth">
                <select @change="pform.selectedSchedule = $event.target.value">
                  <option selected disabled>Select a schedule</option>
                  <option
                    v-for="schedule in pform.schedules"
                    :key="schedule.id"
                    @click="pform.selectedSchedule = schedule.id"
                  >
                    {{ schedule.title }} {{ schedule.subtitle }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <button
                :class="{
                  'is-loading': pform.loading,
                  disabled: pform.loading,
                }"
                @click="pForm(1)"
                class="button is-medium is-fullwidth"
                :disabled="pform.loading"
              >
                Pay & Book
              </button>
            </div>
          </div>
        </div>
        <div v-if="pform.completed" class="columns">
          <div class="column">
            <article class="message is-primary">
              <div class="message-header">
                <p>Thanks!</p>
              </div>
              <div class="message-body">
                Thanks for registering for this class! Our teacher will contact
                you soon.
                <br /><br />
                Link to join the class have been sent to your email.
                <br /><br />
                <u>Here are your class details:</u>
                <ul>
                  <li>
                    Name: <b>{{ pform.name }}</b>
                  </li>
                  <li>
                    Class: <b>{{ cls.name }}</b>
                  </li>
                  <li>
                    Schedule:
                    <b>{{ pform.selectedSchedule }}</b>
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { db } from "@/main.js";
const SteinStore = require("stein-js-client");
const Razorpay = require("razorpay-checkout");
var request = require("request");
export default {
  name: "cls",
  data() {
    return {
      demo: false,
      demolocation: location.hostname == "demo.witblox.com",
      screen: 0,
      id: this.$route.params.id,
      schedule: {
        date: new Date(),
        time: 0,
      },
      name: "",
      desc: "",
      banner: "",
      cls: {},
      faqs: [],
      form: {
        completed: false,
        loading: false,
        error: "",
        name: "",
        email: "",
        phone: "",
        city: "",
      },
      pform: {
        stage: 0,
        completed: false,
        loading: false,
        error: "",
        name: "",
        email: "",
        phone: "",
        city: "",
        schedules: [],
        selectedSchedule: "",
        paid: false,
      },
      teacher: {},
      orderId: "",
      paymentForm: false,
    };
  },
  methods: {
    pForm(stage) {
      if (stage == 0) {
        this.pform.loading = true;
        if (
          this.pform.name &&
          this.pform.email &&
          this.pform.phone &&
          this.pform.city
        ) {
          this.pform.stage = 1;
          this.pform.error = "";
          this.pform.loading = false;
        } else {
          this.pform.error = "Please fill all the fields properly.";
          this.pform.loading = false;
        }
      } else if (stage == 1) {
        this.pform.loading = true;
        var options = {
          method: "POST",
          url: "https://us-central1-witblox-5ae68.cloudfunctions.net/createClassOrderFromWeb",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.pform.name,
            email: this.pform.email,
            phone: this.pform.phone,
            city: this.pform.city,
            selectedSchedule: this.pform.selectedSchedule,
            id: this.id,
          }),
        };
        var prefill = {
          name: this.pform.name,
          email: this.pform.email,
          contact: this.pform.phone,
        };
        request(options, (error, response) => {
          if (error) console.log(error);
          var paymentInfo = JSON.parse(response.body);
          console.log(paymentInfo);
          const options = {
            key: "rzp_live_LxqoqZ6JH2rIT4",
            amount: Number(paymentInfo.amount),
            currency: "INR",
            order_id: paymentInfo.rzp_order_id,
            prefill: prefill,
            handler: (response) => {
              console.log(response.razorpay_payment_id);
              db.collection("classes")
                .doc(this.id)
                .collection("weborders")
                .doc(paymentInfo.id)
                .update({
                  paid: true,
                  rzp_payment_id: response.razorpay_payment_id,
                })
                .then(() => {
                  this.pform.completed = true;
                  this.pform.stage = 2;
                  this.pform.error = "";
                });
            },
          };
          var rzp1 = new Razorpay.Razorpay(options);
          rzp1.on("payment.failed", (errResponse) => {
            console.log(errResponse);
            this.pform.error = errResponse.error.description;
            this.pform.loading = false;
          });
          rzp1.open();
        });
        /*db.collection("classes")
          .doc(this.id)
          .collection("weborders")
          .add({
            paid: false,
            rzp_payment_id: "",
            name: this.pform.name,
            email: this.pform.email,
            phone: this.pform.phone,
            city: this.pform.city,
            schedule: this.pform.selectedSchedule,
            created: new Date().getTime(),
          })
          .then((neworder) => {
            this.orderId = neworder.id;
            console.log(this.cls);
          });*/
      }
    },
    deeplink() {
      if (location.hostname == "demo.witblox.com") {
        window.location.href =
          "https://app.witblox.com/class/" + this.$route.params.id;
      } else {
        window.location.href =
          "https://app.witblox.com/class/" + this.$route.params.id;
      }
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    fillForm() {
      document.getElementById("form").scrollIntoView();
    },
    submitForm() {
      this.form.loading = true;
      const store = new SteinStore(this.teacher.api);
      if (
        this.form.name &&
        this.form.email &&
        this.form.phone &&
        this.form.city
      ) {
        store
          .append("Sheet1", [
            {
              name: this.form.name,
              email: this.form.email,
              phone: this.form.phone,
              city: this.form.city,
              classname: this.cls.name,
            },
          ])
          .then((res) => {
            this.form.completed = true;
            this.form.error = false;
            console.log(res);
          });
      } else {
        this.form.loading = false;
        this.form.error = true;
      }
    },
  },
  beforeMount() {
    if (this.$router.currentRoute.params.teacher) {
      db.collection("teachers")
        .where("slug", "==", this.$router.currentRoute.params.teacher)
        .get()
        .then((teacher) => {
          console.log(teacher.empty);
          this.teacher = teacher.docs[0].data();
          this.teacher.id = teacher.docs[0].id;
        });
    }
    db.collection("classes")
      .doc(this.id)
      .get()
      .then((doc) => {
        if (doc.data().demo) this.demo = true;
        if (new Date().getHours() > doc.data().demotime - 1) {
          //next day
          this.schedule.date = new Date(
            new Date(
              new Date(
                new Date(new Date().setHours(18)).setMinutes(0)
              ).setSeconds(0)
            ).setDate(new Date().getDate() + 1)
          );
        } else {
          //this day
          this.schedule.date = new Date(
            new Date(
              new Date(new Date().setHours(18)).setMinutes(0)
            ).setSeconds(0)
          );
        }
        this.cls = doc.data();
        this.cls.id = doc.id;
        doc.data().faqs.forEach((faq, index) => {
          this.faqs.push({
            id: doc.id + String(index),
            title: faq.title,
            content: faq.content,
            open: false,
          });
        });
      });
    db.collection("classes")
      .doc(this.id)
      .collection("schedule")
      .where("end", ">", new Date().getTime())
      .where("available", "==", true)
      .orderBy("end")
      .get()
      .then((schedules) => {
        for (let index = 0; index < schedules.size; index++) {
          const schedule = schedules.docs[index];
          if (index == 0) this.scheduleId = schedule.id;
          var pushObj = schedule.data();
          pushObj.id = schedule.id;
          pushObj.selected = true;
          console.log(pushObj);
          this.pform.schedules.push(pushObj);
        }
      });
  },
  mounted() {},
};
</script>
<style scoped>
.hero {
  padding-bottom: 80px;
}
.classcontainer {
  padding-bottom: 250px;
}
.popupbox {
  position: fixed;
  display: block;
  width: 100%;
  padding: 30px;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%),
    0 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 30px 30px 0 0;
  height: 0px;
  transition: height 2s;
}
.popupbox.open {
  height: auto;
  max-height: 60%;
  overflow: auto;
}
.popupbox .icon {
  padding-top: 13px;
  font-size: 1.4rem;
}
.subtotal .title {
  font-size: 1.25rem;
}
.subtotal .subtitle {
  font-size: 1.1rem;
}
.subtotal .column {
  padding: 0.5rem 0.75rem 0 0.75rem;
}
.blur {
  transition: filter 1s;
  filter: blur(6px);
}
.schedule {
  margin-bottom: 5px;
}
.schedule .card {
  border-radius: 20px;
}
.schedule .card.selected {
  border: 3px solid #000;
  background: #ece8e8;
}
.schedule .card-content {
  padding: 1rem 0.75rem 0.75rem 1.5rem;
}
.schedule h6 {
  font-size: 1.15rem;
  color: #000;
  font-weight: 600;
}
.schedule p {
  font-size: 1.05rem;
}
.lessons img {
  border-radius: 20px 0 0 20px;
}
.lessons p {
  font-weight: 500;
  font-size: 0.9rem;
}
.lessons h6 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 4px;
}
.videoBtns {
  text-align: center;
}
.videoBtns .button {
  font-size: 1.2rem;
  background: #000;
  border-radius: 10px;
}
.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}
.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}
.title,
.subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}
.lessons h6 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 4px;
}
.lessons p {
  font-weight: 500;
  font-size: 0.9rem;
}
.trailerVidBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  padding: 15px 30px 10px 30px;
  /* border-radius: 30px 30px 10px 10px; */
  background: rgb(231 231 231 / 79%);
}
.button.btn {
  height: auto !important;
}
.btn-theme,
.login-signup .btn-theme {
  border: 0;
  background-color: #ff6e00;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
  padding: 11px 37px;
  line-height: 22px;
  border-radius: 8px 8px 8px 8px;
  outline: none;
  transition: 0.3s;
  font-size: 18px;
  padding: 16.5px 29.6px;
  background-image: url(~@/assets/img/button.svg);
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
iframe {
  width: 100% !important;
  height: 1200px;
}
.field .button.is-fullwidth {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: #000;
  color: #fff;
}
.input,
.textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.paymentcollection {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 30%;
  padding-top: 100px;
}
.paymentform {
  max-width: 500px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .paymentcollection {
    padding: 8%;
    padding-top: 20%;
  }
}
</style>
<style>
.details ul {
  list-style-type: disc;
  padding-left: 25px;
}
.details h2 {
  padding-bottom: 12.5px;
}
.details video {
  border: 4px solid #000;
  margin-bottom: 25px;
  width: 100% !important;
}
.paymentcollection {
  background: #ffffff69 !important;
}
.paymentcollection .paymentForm {
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  border: 3px solid #000;
}
</style>
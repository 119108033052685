import Vue from "vue";
import App from "./App.vue";
import Router from "vue-router";
import VueGtag from "vue-gtag";

import "./assets/css/main.css";

import home from "./components/home.vue";
import cls from "./components/cls.vue";
import teacher from "./components/teacher.vue";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

/* icons */
// import "material-design-icons/iconfont/material-icons.css";

const firebaseConfig = {
	apiKey: "AIzaSyCQOZ2Re2ytVpz7KiKhqrQcV3GNG9r8pZk",
	authDomain: "witblox-5ae68.firebaseapp.com",
	databaseURL: "https://witblox-5ae68.firebaseio.com",
	projectId: "witblox-5ae68",
	storageBucket: "witblox-5ae68.appspot.com",
	messagingSenderId: "532604685774",
	appId: "1:532604685774:web:a895ff5bd01df136edaf9f",
	measurementId: "G-YKYYG1KZKB",
};
// FirebaseAnalytics.initializeFirebase(firebaseConfig);
firebase.initializeApp(firebaseConfig);
// firebase.analytics()
export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const storage = firebase.storage();

Vue.use(Router);
Vue.use(VueGtag, {
	config: { id: "G-LRJWZRVL5N" },
});

Vue.config.productionTip = false;

//paths
const router = new Router({
	routes: [
		{
			path: "/",
			name: "home",
			component: home,
			meta: {
				requiresAuth: false,
			},
		},
		{
			path: "/teacher/:id",
			name: "teacher",
			component: teacher,
			meta: {
				requiresAuth: false,
			},
		},
		{
			path: "/class/:id/:teacher?",
			name: "cls",
			component: cls,
			meta: {
				requiresAuth: false,
			},
		},
	],
});

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");

router.afterEach((to, from) => {
	console.log(to, from);
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
});
